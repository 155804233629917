import { render, staticRenderFns } from "./RegReportingBlindModule.vue?vue&type=template&id=71a5c82e&scoped=true&"
import script from "./RegReportingBlindModule.vue?vue&type=script&lang=ts&"
export * from "./RegReportingBlindModule.vue?vue&type=script&lang=ts&"
import style0 from "./RegReportingBlindModule.vue?vue&type=style&index=0&id=71a5c82e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a5c82e",
  null
  
)

export default component.exports