var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reg-rep-list",
    class: _vm.confirmed ? 'reg-rep-list--confirmed' : ''
  }, [_c('b-table', _vm._g(_vm._b({
    staticClass: "sygni-b-table reg-rep-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "show-empty": true,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "busy": _vm.isBusy
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(fileName)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(rowData.item.fileName) + " ")];
      }
    }, {
      key: "cell(category)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [!_vm.confirmed ? _c('sygni-select', {
          ref: "categorySelect",
          class: ['td__select', _vm.showCategoryGuide && !(rowData !== null && rowData !== void 0 && (_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.category) ? 'highlight' : '', 'remove-max-h'],
          attrs: {
            "value": rowData.item.category,
            "placeholder": 'Choose category',
            "options": _vm.categories,
            "disabled": _vm.isDisabled,
            "searchable": false,
            "clearable": false
          },
          on: {
            "input": function input($event) {
              return _vm.setCategory($event, rowData.item);
            }
          }
        }) : _vm._e(), _vm.confirmed ? _c('div', {
          staticClass: "category--confirmed"
        }, [_vm._v(" " + _vm._s(rowData.item.category) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions"
        }, [_c('div', {
          staticClass: "action"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DOWNLOAD')
          },
          on: {
            "click": function click($event) {
              return _vm.downloadFile(rowData.item);
            }
          }
        })]), !_vm.confirmed ? _c('div', {
          class: ['action', _vm.isDisabled ? 'disabled' : '']
        }, [_vm.updateOnChange ? _c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DELETE')
          },
          on: {
            "click": function click($event) {
              return _vm.deleteFile(rowData.item);
            }
          }
        }) : _vm._e(), !_vm.updateOnChange ? _c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DELETE')
          },
          on: {
            "click": function click($event) {
              return _vm.deleteFileWithoutUpdate(rowData === null || rowData === void 0 ? void 0 : rowData.index);
            }
          }
        }) : _vm._e()]) : _vm._e()])];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "no-files"
        }, [_vm._v("No files attached.")])];
      },
      proxy: true
    }])
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }