






















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniAccessModal from "@/components/layout/SygniAccessModal.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import FundsTable from "@/modules/genprox/modules/AIFM/modules/dashboard/components/FundsTable.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import FundManagerCard from "@/modules/genprox/modules/AIFM/components/FundManagerCard.vue";
import ManagementTeam from "@/modules/genprox/modules/AIFM/components/ManagementTeamCard.vue";
import FundInformationCard from "@/modules/genprox/modules/AIFM/components/FundInformationCard.vue";
import ReportingRegulatoryCard
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepCard.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import RegBlindRepCard from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegBlindRepCard.vue";
import {Prop} from "vue-property-decorator";
import {FundManager, MTMember} from "@/modules/genprox/models/User";
import {Report} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";

@Component({
  components: {
    RegBlindRepCard,
    SygniRoundedButton,
    ReportingRegulatoryCard,
    FundInformationCard,
    ManagementTeam, FundManagerCard, UserPresentation, FundsTable, SygniContainerTitle, SygniCard, SygniAccessModal},
})
export default class RegReportingBlindModule extends Vue {
  @Prop() openCardId!: string;
  fundManagerData: FundManager = {
    "id": "c7ec0363-fdc2-4fa9-b407-7f967a095bc3",
    "name": "Demo Fund Gold",
    "logo": "https://cdn.platform.fundequate.com/1612465955_4673b1674cc9_logo-Gold.png",
    "email": "DemoFund1@genprox.com",
    "taxNumber": "7010612122",
    "registerNumber": "0000635591",
    "statisticalNumber": "365352584",
    "address": {
        "street": "Puławska",
        "houseNumber": "1",
        "flatNumber": null,
        "city": "Warsaw",
        "zipCode": "02-492",
        "country": "PL"
    },
    "description": "Our team of financial consultants has the necessary expertise in handling all the procedures related to the registration of an investment fund in this country, following the latest modifications of the legislation. Investors can also receive assistance during the registration with the country’s financial institutions and may provide in-depth information regarding the compulsory documents that are requested in this case, depending on the type of fund selected for registration."
  };

  managementTeam: MTMember[] = [
    {
        "naturalPersonId": "a2cb701c-87a7-4f41-973e-962c975c3a1e",
        "name": "Max Smith",
        "logo": "https://cdn.platform.fundequate.com/1596569241_6c5adc683749_user-photo--11.PNG",
        "email": "DemoFund2@genprox.com",
        "phone": "+48601616910",
        "position": "Partner"
    },
    {
        "naturalPersonId": "8bc47e3c-e90b-494b-a39b-e26484acfd88",
        "name": "Calvin Harris",
        "logo": "https://cdn.platform.fundequate.com/1596569250_7eaddba73d97_user-photo--111.PNG",
        "email": "DemoFund4@genprox.com",
        "phone": "+44668435100",
        "position": "CEO"
    },
    {
        "naturalPersonId": "67b45f8a-4b0a-404d-8f03-eca0639b7c74",
        "name": "Leo Hansen",
        "logo": "https://cdn.platform.fundequate.com/1596569712_32ef62d2afe9_user-photo--1111.PNG",
        "email": "DemoFund1@genprox.com",
        "phone": "+48450333100",
        "position": "Managing Partner, Co-Founder"
    }
];

  reports: Array<Report> = [
    {
      "id": "76d2f933-68cc-4a08-84e2-c300e2edb0f4",
      "fundManagerId": "c7ec0363-fdc2-4fa9-b407-7f967a095bc3",
      "year": 2021,
      "status": "new",
      "outputFiles": [],
      "funds": [],
      "data": null,
    },
    {
      "id": "bedcde78-ceec-430a-9de6-5789f1919bf3",
      "fundManagerId": "c7ec0363-fdc2-4fa9-b407-7f967a095bc3",
      "year": 2022,
      "status": "new",
      "outputFiles": [],
      "funds": [],
      "data": null,
    }
  ];

  get nameSymbol(): string {
    return this.fundManagerData.name?.slice(0,1);
  }

  activeUserContextId!: string;
}

