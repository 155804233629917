var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [_c('div', {
    staticClass: "dashboard__left"
  }, [_c('FundManagerCard', {
    attrs: {
      "fundManagerData": _vm.fundManagerData
    }
  }), _c('sygni-rounded-button', {
    staticClass: "filled gn-primary add-new-report",
    attrs: {
      "selectable": false,
      "plus-icon": true
    }
  }, [_vm._v(" Add new reporting ")]), _c('sygni-container-title', {
    staticClass: "fund-title"
  }, [_vm._v(" Regulatory Reporting ")]), _vm._l(_vm.reports, function (report) {
    return _c('RegBlindRepCard', {
      key: report.id,
      attrs: {
        "report": report
      }
    });
  })], 2), _c('div', {
    staticClass: "dashboard__right"
  }, [_c('sygni-container-title', [_vm._v(" Management Team ")]), _c('management-team', {
    attrs: {
      "team": _vm.managementTeam
    }
  }), _c('sygni-container-title', [_vm._v(" Information ")]), _c('fund-information-card', {
    attrs: {
      "legalEntity": _vm.fundManagerData
    }
  })], 1), _c('sygni-access-modal')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }