var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reporting-regulatory-card"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Regulatory reporting for " + _vm._s(_vm.report.year) + " "), _c('span', {
    staticClass: "grey"
  }, [_vm._v("(KNF reporting)")])]), _c('div', {
    staticClass: "toggle-show"
  }, [[_vm._v(" Show "), _c('img', {
    attrs: {
      "src": require("@/assets/icons/arrow.svg")
    }
  })]], 2)]), !_vm.isOpen && _vm.report.status === _vm.reportStatuses.NEW ? _c('reg-rep-hidden-card') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }