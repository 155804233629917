var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reg-rep-list-hidden-content"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/home.svg")
    }
  }), _c('span', {
    staticClass: "filename"
  }, [_vm._v(_vm._s(_vm.confirmedReport.name))]), _c('div', {
    staticClass: "download"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DOWNLOAD')
    },
    on: {
      "click": function click($event) {
        return _vm.download();
      }
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }