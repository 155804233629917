import { render, staticRenderFns } from "./RegRepCard.vue?vue&type=template&id=8e7c836a&scoped=true&"
import script from "./RegRepCard.vue?vue&type=script&lang=ts&"
export * from "./RegRepCard.vue?vue&type=script&lang=ts&"
import style0 from "./RegRepCard.vue?vue&type=style&index=0&id=8e7c836a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e7c836a",
  null
  
)

export default component.exports