


















































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import {BSpinner, BvTableField} from "bootstrap-vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import {Prop, Watch} from "vue-property-decorator";
import { mapGetters } from "vuex";
import Utils from "@/modules/shared/utils/utils";
import { BACKEND_BASE_URL} from "@/shared/consts";
import {
  DeleteFileDto,
  UploadFileToReportDTO
} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/store/types";
import {AIFMSelects} from "@/modules/shared/dictionary-store/types";
import {Report, ReportFile} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";
import SygniTableLegacy from "@/components/table/SygniTableLegacy.vue";

@Component({
  components: {
    SygniSelect,
    SygniRoundedButton,
    UserPresentation,
    ExpandCollapse, BSpinner },
  computed: {
    ...mapGetters('dictionary', {
      AIFMSelects: 'AIFMSelects'
    })
  }
})
export default class RegRepList extends SygniTableLegacy<ReportFile> {

  AIFMSelects!: AIFMSelects;
  @Prop({default: false}) confirmed!: boolean;
  @Prop({default: ''}) report: Report;
  @Prop({default: true}) updateOnChange: boolean;
  @Prop({default: false}) isDisabled: boolean;
  @Prop({default: false}) showCategoryGuide: boolean;

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'fileName', label: 'NAME', class: 'name' },
    { key: 'category', label: 'CATEGORY', class:'select' },
    { key: 'actions', class:'actions' },
  ];

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted(){
    this.onMounted();
    this.setBorderlessTds();
  }

  get categories() {
    return this.AIFMSelects.categories.filter((el: any) => el.label !== 'Financial Statement XML')
  }

  setCategory(category: string , file: ReportFile){
    if (this.updateOnChange) {
      const payload: UploadFileToReportDTO = {
        reportId: this.report.id,
        inputFileId: file.id,
        payload: {
          fundId: this.report.funds.find( fund => fund.inputFiles.find( fundFile => fundFile.id === file.id)).id,
          fileId: file.fileId,
          category: category
        }
      }
      const updatedReport: Report = JSON.parse(JSON.stringify(this.report));
      updatedReport.funds.forEach( (fund) => {
        fund.inputFiles.forEach( inputFile => {
          if(inputFile.id === file.id){
            inputFile.category = category;
          }
        });
      });
      this.$store.commit('regReporting/updateReport', updatedReport);
      this.$store.dispatch('regReporting/updateReportFile', payload);
    }
    this.$emit('setCategory', { category, file })
  }

  async downloadFile(file: ReportFile): Promise<void> {
    let fileId: string = file?.id
    if (file?.fileId) {
      fileId = file?.fileId
    }
    const fileObject = await this.$store.dispatch('genprox/getFileById', fileId)
    Utils.downloadFileByUrl(BACKEND_BASE_URL + fileObject.filePath, file.fileName);
  }

  async deleteFile(file:ReportFile): Promise<void> {
    if (this.updateOnChange) {
      const payload: DeleteFileDto = {fileId: file.id, reportId: this.report.id};
      try{
        await this.$store.dispatch('regReporting/deleteReportInputFile',payload);
        const updatedReport: Report = JSON.parse(JSON.stringify(this.report));
        updatedReport.funds.forEach( (fund) => {
            for(let i = 0; i < fund.inputFiles.length; i++){
              if(fund.inputFiles[i].id === file.id){
                fund.inputFiles.splice(fund.inputFiles.indexOf(fund.inputFiles[i]), 1);
                break;
              }
          }
        });
        this.$store.commit('regReporting/updateReport', updatedReport);
      } catch(e){
        e;
      }
    }
  }

  deleteFileWithoutUpdate(index: number) {
    if (!this.isDisabled) {
      this.$emit('deleteFile', index)
    }
  }

  @Watch('showCategoryGuide', { immediate: true }) onShowCategoryGuideUpdate() {
    if (this.showCategoryGuide) {
      (this.$refs?.categorySelect as any)?.$el?.click()
    }
  }
}

