












import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniDroparea from "@/components/inputs/SygniDroparea.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import ReportingRegulatoryList
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepList.vue";
import RepRegHiddenCardContent
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepHiddenCard.vue";
import SygniLine from "@/components/layout/SygniLine.vue";

@Component({
  components: {
    SygniLine,
    RepRegHiddenCardContent,
    ReportingRegulatoryList,
    SygniRoundedButton, SygniDroparea, UserPresentation, SygniContainerTitle, SygniCard}
})
export default class RegRepCreatingRaport extends Vue {

}
