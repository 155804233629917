var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-card', {
    staticClass: "inner-card sygni-card--borderless"
  }, [_c('div', {
    staticClass: "reg-rep-list-hidden-content"
  }, [_c('div', {
    staticClass: "information"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/docs.svg")
    }
  }), _c('span', [_vm._v(" Show details to "), _c('span', {
    staticClass: "red"
  }, [_vm._v("add documents")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }