





















































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import ReportingRegulatoryList
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepList.vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import RegRepCreatingRaport
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepCreatingRaport.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import { Prop, Watch } from "vue-property-decorator";
import Utils, { UploadProgress } from "@/modules/shared/utils/utils";
import { BProgress, BSpinner } from "bootstrap-vue";
import RegRepHiddenCard from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepHiddenCard.vue";
import { BACKEND_BASE_URL } from "@/shared/consts";
import FileUploader from "@/components/FileUploader.vue";
import { AxiosRequestConfig } from "axios";
import RegRepHiddenConfirmedCard
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepHiddenConfirmedCard.vue";
import {
  ReportStatuses,
  UploadFileToReportDTO
} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/store/types";
import { Fund } from "@/modules/genprox/models/User";
import { Report, ReportFile } from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";
import { UploadedFileInfo } from "@/modules/genprox/models/Shared";

export enum RegRepTableActions {
  // eslint-disable-next-line no-unused-vars
  download = 'DOWNLOAD',
  // eslint-disable-next-line no-unused-vars
  delete = 'DELETE'

}

@Component({
  components: {
    RegRepHiddenConfirmedCard,
    FileUploader,
    ExpandCollapse,
    RegRepCreatingRaport,
    SygniLine,
    RegRepHiddenCard,
    ReportingRegulatoryList,
    SygniRoundedButton, UserPresentation, SygniContainerTitle, SygniCard, BProgress, BSpinner
  }
})
export default class RegRepCard extends Vue {
  readonly reportStatuses = ReportStatuses;
  readonly supportedFileFormats: Array<string> = ['doc', 'docx', 'pdf', 'xlsx', 'xls'];

  @Prop() report!: Report;
  @Prop() openCardId!: string;
  fundManagerData: Fund = {};
  uploadProgress: UploadProgress = { progress: 0 };
  reportGeneratingLoading: boolean = false;
  isOpen: boolean = false;
  isLoadingReport: boolean = false;
  editedReportId: number | null = null;

  get isConfirmed(): boolean {
    return this.report.status === this.reportStatuses.GENERATED;
  }

  get isReportReadyToCreate(): boolean {
    let isReady = true;
    this.report.funds.forEach(fund => {
      if (fund.inputFiles?.length > 0) {
        fund.inputFiles.forEach(inputFile => {
          if (!inputFile.category || inputFile.category === '') {
            isReady = false;
          }
        });
      } else {
        isReady = false;
      }
    });
    return isReady;
  }

  @Watch('openCard', { immediate: true }) onOpenCardChange() {
    if (this.report.id === this.openCardId) {
      this.toggleOpen();
    }
  }

  async mounted(): Promise<void> {
  }

  async toggleOpen(): Promise<void> {
    if (!(this.report.funds?.length > 0)) {
      this.isLoadingReport = true;
      try {
        const getContextDataRequest = this.$store.dispatch('genprox/getLeContextData', this.report.fundManagerId);
        await this.$store.dispatch('regReporting/getReport', this.report.id);
        this.fundManagerData = await getContextDataRequest;
      } catch (e) {
        e;
      }
      this.isLoadingReport = false;
    }
    this.isOpen = !this.isOpen;
  }

  async createReport(): Promise<void> {
    try {
      this.reportGeneratingLoading = true;
      await this.$store.dispatch('regReporting/generateReport', this.report.id);
      this.$store.commit('regReporting/updateReport', { ...this.report, ...{ status: ReportStatuses.GENERATED } });
      this.isOpen = false;
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Upload success',
        text: 'Report has been successfully sent'
      });


    } catch (e) {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'An error occurred',
        text: this.$t(e.response.data.label).toString()
      });
    }
    this.reportGeneratingLoading = false;
  }

  async uploadFile(file: File, fund: Fund): Promise<void> {
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);

    try {
      const info: UploadedFileInfo = await this.$store.dispatch('genprox/uploadFile', { file, config });
      const fileToReportDTO: UploadFileToReportDTO = {
        payload: {
          fileId: info.id,
          category: null,
          fundId: fund.id
        },
        reportId: this.report.id
      }

      await this.$store.dispatch('regReporting/uploadReportFile', fileToReportDTO);
      await this.$store.dispatch('regReporting/getReport', this.report.id);
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'File has been successfully added'
      });
    } catch (e) {
      e;
    }
  }

  async addFile(file: File[], fund: Fund): Promise<void> {
    if (file.length > 1) {
      for (let i = 0; i < file.length; i++) {
        this.uploadFile(file[i], fund);
      }
    } else {
      this.uploadFile(file[0], fund);
    }

    this.uploadProgress.progress = -1;
  }

  downloadFile(file: ReportFile): void {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.filePath}`, file.fileName);
  }

  isXmlReport(file: { fileName: string; }) {
    if (file?.fileName) {
      const fileFormat = (/[.]/.exec(file.fileName)) ? /[^.]+$/.exec(file.fileName)[0] : undefined
      return fileFormat === 'xml';
    }
    return false;
  }

}
