var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-card', {
    staticClass: "inner-card sygni-card--borderless"
  }, [_c('div', {
    staticClass: "reg-rep-creating-report"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/docs.svg")
    }
  }), _c('span', [_vm._v(" We are creating a report for you. We'll come back to you "), _c('span', {
    staticClass: "red"
  }, [_vm._v("asap.")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }