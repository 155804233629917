










import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import {BSpinner} from "bootstrap-vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import Vue from "vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import {Prop} from "vue-property-decorator";


@Component({
  components: {
    SygniLine,
    SygniSelect,
    SygniRoundedButton,
    UserPresentation,
    ExpandCollapse, BSpinner },
})
export default class RepRegHiddenListContent extends Vue {
  @Prop() confirmedReport!: any;

  download(): void {

  }

  mounted(): void {
  }
}

export interface RegRepListItem {
}

